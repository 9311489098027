import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

// index page sections
import Download from "../IndexSections/Download.js";

class Landing extends React.Component {
  state = { subject: "", body: "" };
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      <h1 className="display-3 text-white">
                        Hi! My name is Taha.
                        <span>Entrepreneur & Programmer</span>
                      </h1>
                      <p className="lead text-white">
                        I spend most of my time coding, working on my ideas, and
                        helping companies unlock their digital potential. If I’m
                        not in front of my laptop, you’d definitely find me on
                        the wrestling mat.{" "}
                        <span role="img" aria-label="wrestling">
                          🤼‍♂️
                        </span>
                        <br />
                        My motto in life:{" "}
                        <strong>It's not over until I win.</strong>
                      </p>
                      <div className="btn-wrapper"></div>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-atom" />
                          </div>
                          <h6 className="text-primary text-uppercase">
                            Software Engineer
                          </h6>
                          <p className="description mt-3">
                            I'm a lifelong programmer. I develop web platforms
                            and mobile applications. I love working with
                            companies and helping them launch and maintain their
                            tech.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-spaceship" />
                          </div>
                          <h6 className="text-success text-uppercase">
                            Entrepreneur
                          </h6>
                          <p className="description mt-3">
                            As of 2017, I'm investing in multiple early stage
                            startups. With my company ShopiApps, We create
                            platforms to help businesses make more money.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-lock-circle-open" />
                          </div>
                          <h6 className="text-warning text-uppercase">
                            Penetration Tester
                          </h6>
                          <p className="description mt-3">
                            I spend my free time searching for bugs and
                            vulnerablities on the web. If you too are a bug
                            bounty hunter, I'm interested in knowing more about
                            you.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Companies I worked for</h2>
                  <p className="lead text-muted">
                    I worked with tens of startups and companies delivering many
                    types of products. I have hands-on experience in finance &
                    banking, tourism, ticketing, e-commerce, management
                    platforms, gaming and intelligence platforms.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <a
                      href="https://www.audi.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="Audi"
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/theme/audi.jpg")}
                        style={{ width: "200px" }}
                      />
                    </a>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <a
                      href="https://www.aboutyou.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="About You"
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/theme/aboutyou.png")}
                        style={{ width: "200px" }}
                      />
                    </a>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <a
                      href="https://www.giz.de"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="GIZ"
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/theme/giz.jpg")}
                        style={{ width: "200px" }}
                      />
                    </a>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <a
                      href="https://www.ebi.ac.uk/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="EMBL"
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/theme/embl.png")}
                        style={{ width: "200px" }}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg pt-0">
            <Container>
              <Card className="bg-gradient-warning shadow-lg border-0">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="8">
                      <h3 className="text-white">
                        Would you like to work with me?
                      </h3>
                      <p className="lead text-white mt-3">
                        I currently do freelance work while I’m building my next
                        big thing. I'll be happy to have a call and chat about
                        your product or idea and explore ways we can work
                        together.
                      </p>
                    </Col>
                    <Col className="ml-lg-auto" lg="3">
                      <Button
                        block
                        className="btn-white"
                        color="default"
                        href="mailto:contact@btaha.com"
                        target="_blank"
                        size="lg"
                      >
                        Get in touch
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
          </section>
          <section className="section section-lg bg-gradient-default">
            <Container className="pt-lg pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">
                    Get In Touch, Why Not?
                  </h2>
                  <p className="lead text-white">
                    If you would love to contact me, please feel free to drop me
                    a line. If you don't get an answer immediately, I might just
                    be travelling through the middle of nowhere. <br />
                    I'll get back to you as soon as I can. That's a promise!
                  </p>
                  <h2 className="display-3">
                    <span className="text-success">
                      Things I look forward to
                    </span>
                  </h2>
                </Col>
              </Row>
              <Row className="row-grid mt-5">
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-spaceship text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Project inquiries</h5>
                  <p className="text-white mt-3">
                    Are you looking to build a prototype or a stable kickass
                    product? I may help with that.
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-satisfied text-primary" />
                  </div>
                  <h5 className="text-white mt-3">You’re awesome emails</h5>
                  <p className="text-white mt-3">
                    Thank you ^_^ You made my day! You’re awesome too :D
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-planet text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Events</h5>
                  <p className="text-white mt-3">
                    I'd be happy to attend tech events and network with other
                    awesome people.
                  </p>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Need to contact me?</h4>
                      <p className="mt-0">
                        I would be happy to receive your email.
                      </p>
                      <FormGroup
                        className={classnames("mt-5", {
                          focused: this.state.nameFocused,
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Subject"
                            type="text"
                            onFocus={(e) =>
                              this.setState({ nameFocused: true })
                            }
                            onBlur={(e) =>
                              this.setState({ nameFocused: false })
                            }
                            onChange={(e) =>
                              this.setState({ subject: e.target.value })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Input
                          className="form-control-alternative"
                          cols="80"
                          name="message"
                          placeholder="Type a message..."
                          rows="5"
                          type="textarea"
                          onChange={(e) =>
                            this.setState({ body: e.target.value })
                          }
                        />
                      </FormGroup>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                          href={
                            "mailto:contact@btaha.com?subject=" +
                            this.state.subject +
                            "&body=" +
                            this.state.body
                          }
                          target="_blank"
                        >
                          Send Message
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
          <Download />
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Landing;
